var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"clients"},[_c('create-client',{attrs:{"create-modal":_vm.createModal},on:{"closeCreateModal":_vm.closeCreateModal,"failedCreatingClient":_vm.failedCreatingClient,"clientSaved":_vm.clientSaved}}),_c('edit-client',{attrs:{"edit-modal":_vm.editModal,"client":_vm.selectedClient && _vm.selectedClient.length ? _vm.selectedClient[0] : {}},on:{"closeEditModal":_vm.closeEditModal,"failedEditingClient":_vm.failedEditingClient,"clientEditSaved":_vm.clientEditSaved}}),_c('v-row',[_c('v-spacer'),(_vm.selectedClient && _vm.selectedClient.length)?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"red darken-2"},on:{"click":function($event){return _vm.deleteClient()}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e(),(
        _vm.selectedClient &&
        _vm.selectedClient.length &&
        _vm.selectedClient[0].clientStatus !== 'LOCKED'
      )?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"red"},on:{"click":function($event){return _vm.lockClient()}}},[_c('v-icon',[_vm._v("mdi-lock")])],1):_vm._e(),(
        _vm.selectedClient &&
        _vm.selectedClient.length &&
        _vm.selectedClient[0].clientStatus === 'LOCKED'
      )?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.unlockClient()}}},[_c('v-icon',[_vm._v("mdi-lock-open")])],1):_vm._e(),(_vm.selectedClient && _vm.selectedClient.length)?_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){_vm.editModal = true}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),_c('v-btn',{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"indigo"},on:{"click":function($event){_vm.createModal = true}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('v-data-table',{staticClass:"click-table",attrs:{"headers":_vm.headers,"items":_vm.clients,"item-key":"_id","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"loading":_vm.dataLoading,"show-select":"","single-select":""},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"click:row":_vm.handleRowClick},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
      var value = ref.value;
return [_c('time',{attrs:{"title":value,"datetime":value}},[_vm._v(_vm._s(_vm.formatDate(value)))])]}}]),model:{value:(_vm.selectedClient),callback:function ($$v) {_vm.selectedClient=$$v},expression:"selectedClient"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }