<template>
  <div class="clients">
    <create-client
      :create-modal="createModal"
      @closeCreateModal="closeCreateModal"
      @failedCreatingClient="failedCreatingClient"
      @clientSaved="clientSaved"
    ></create-client>
    <edit-client
      :edit-modal="editModal"
      :client="selectedClient && selectedClient.length ? selectedClient[0] : {}"
      @closeEditModal="closeEditModal"
      @failedEditingClient="failedEditingClient"
      @clientEditSaved="clientEditSaved"
    ></edit-client>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        v-if="selectedClient && selectedClient.length"
        class="mx-2"
        fab
        dark
        small
        color="red darken-2"
        @click="deleteClient()"
      >
        <v-icon>mdi-delete</v-icon>
      </v-btn>
      <v-btn
        v-if="
          selectedClient &&
          selectedClient.length &&
          selectedClient[0].clientStatus !== 'LOCKED'
        "
        class="mx-2"
        fab
        dark
        small
        color="red"
        @click="lockClient()"
      >
        <v-icon>mdi-lock</v-icon>
      </v-btn>
      <v-btn
        v-if="
          selectedClient &&
          selectedClient.length &&
          selectedClient[0].clientStatus === 'LOCKED'
        "
        class="mx-2"
        fab
        dark
        small
        color="green"
        @click="unlockClient()"
      >
        <v-icon>mdi-lock-open</v-icon>
      </v-btn>
      <v-btn
        v-if="selectedClient && selectedClient.length"
        class="mx-2"
        fab
        dark
        small
        color="primary"
        @click="editModal = true"
      >
        <v-icon>mdi-pencil</v-icon>
      </v-btn>
      <v-btn
        class="mx-2"
        fab
        dark
        small
        color="indigo"
        @click="createModal = true"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-row>
    <v-row align="center">
      <v-col>
        <v-data-table
          v-model="selectedClient"
          class="click-table"
          :headers="headers"
          :items="clients"
          item-key="_id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :loading="dataLoading"
          @click:row="handleRowClick"
          show-select
          single-select
        >
          <template v-slot:item.createdAt="{ value }">
            <time :title="value" :datetime="value">{{
              formatDate(value)
            }}</time>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import CreateClient from "@/components/CreateClient.vue";
import EditClient from "@/components/EditClient.vue";

export default {
  components: {
    CreateClient,
    EditClient,
  },
  data() {
    return {
      clients: [],
      selectedClient: undefined,
      sortBy: "name",
      sortDesc: false,
      dataLoading: true,
      createModal: false,
      editModal: false,
    };
  },
  created() {
    this.loadClients();
  },
  computed: {
    headers() {
      return [
        {
          text: "Client Name",
          value: "name",
        },
        {
          text: "Cliend ID",
          value: "_id",
        },
        {
          text: "Status",
          value: "clientStatus",
        },
        { text: "Date Created", value: "createdAt" },
      ];
    },
  },
  methods: {
    formatDate(dt) {
      return moment(dt).format("MM/DD/YYYY hh:mm");
    },
    async loadClients() {
      try {
        const clientsResp = await this.$http.get(`/clients`);

        this.clients = clientsResp.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.dataLoading = false;
      }
    },
    closeCreateModal() {
      this.createModal = false;
    },
    failedCreatingClient(error) {
      console.log(error);
      this.createModal = false;
    },
    clientSaved(data) {
      this.clients.push(data);
      this.createModal = false;
    },
    closeEditModal() {
      this.editModal = false;
    },
    failedEditingClient(error) {
      console.log(error);
      this.editModal = false;
    },
    clientEditSaved() {
      this.loadClients();
      this.editModal = false;
    },
    handleRowClick(row) {
      this.$router.push({ name: "client", params: { clientId: row._id } });
    },
    async deleteClient() {
      const client = this.selectedClient[0];
      try {
        await this.$http.delete(`/clients/${client._id}`);
        this.selectedClient = undefined;
        this.loadClients();
      } catch (error) {
        console.log(error);
      }
    },
    async lockClient() {
      const client = this.selectedClient[0];
      try {
        await this.$http.get(`/clients/lock/${client._id}`);
        this.selectedClient = undefined;
        this.loadClients();
      } catch (error) {
        console.log(error);
      }
    },
    async unlockClient() {
      const client = this.selectedClient[0];
      try {
        await this.$http.get(`/clients/unlock/${client._id}`);
        this.selectedClient = undefined;
        this.loadClients();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
