<template>
  <v-dialog
    v-model="editModal"
    max-width="650px"
    @click:outside="closeEditModal()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Edit Client</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="client.name"
                  label="Client name*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="client.contactFirstName"
                  label="Contact first name"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="client.contactLastName"
                  label="Contact last name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="client.contactEmail"
                  label="Contact email address*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="client.address"
                  label="Address"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="client.city" label="City"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="client.zipCode"
                  label="Zip Code"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="client.country"
                  label="Country"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeEditModal()"> Close </v-btn>
        <v-btn color="primary" text @click="saveClient()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      plans: [
        {
          id: "750",
          name: "750 prospects / mo.",
        },
        {
          id: "1500",
          name: "1,500 prospects / mo.",
        },
        {
          id: "2500",
          name: "2,500 prospects / mo.",
        },
        {
          id: "4000",
          name: "4,000 prospects / mo.",
        },
      ],
    };
  },
  props: {
    editModal: Boolean,
    client: Object,
  },
  methods: {
    closeEditModal() {
      this.$emit("closeEditModal");
    },
    async saveClient() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        const response = await this.$http.put(
          `/clients/${this.client._id}`,
          this.client
        );
        this.$emit("clientEditSaved", response.data);
      } catch (err) {
        this.$emit("failedEditingClient", err);
      }
    },
  },
};
</script>
