<template>
  <v-dialog
    v-model="createModal"
    max-width="650px"
    @click:outside="closeModal()"
  >
    <v-card>
      <v-card-title>
        <span class="headline">Add Client</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-form ref="form" class="mx-2" lazy-validation>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="name"
                  label="Client name*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="contactFirstName"
                  label="Contact first name"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="contactLastName"
                  label="Contact last name"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="contactEmail"
                  label="Contact email address*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field v-model="address" label="Address"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="city" label="City"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="zipCode" label="Zip Code"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="country" label="Country"></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-select
                  v-model="planId"
                  :items="plans"
                  item-text="name"
                  item-value="id"
                  label="Plan Id*"
                  required
                ></v-select>
              </v-col>
              <v-col cols="6">
                <v-switch
                  v-model="trialMonth"
                  label="Activate trial month"
                ></v-switch>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <small>* indicates required field</small>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="closeModal()"> Close </v-btn>
        <v-btn color="primary" text @click="createClient()"> Save </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      contactFirstName: "",
      contactLastName: "",
      contactEmail: "",
      address: "",
      city: "",
      zipCode: "",
      country: "",
      planId: undefined,
      trialMonth: false,
      plans: [
        {
          id: "750",
          name: "750 prospects / mo.",
        },
        {
          id: "1500",
          name: "1,500 prospects / mo.",
        },
        {
          id: "2500",
          name: "2,500 prospects / mo.",
        },
        {
          id: "4000",
          name: "4,000 prospects / mo.",
        },
      ],
    };
  },
  props: {
    createModal: Boolean,
  },
  methods: {
    closeModal() {
      this.$emit("closeCreateModal");
    },
    async createClient() {
      if (!this.$refs.form.validate()) {
        return;
      }

      try {
        const response = await this.$http.post("/clients", {
          name: this.name,
          contactFirstName: this.contactFirstName,
          contactLastName: this.contactLastName,
          contactEmail: this.contactEmail,
          address: this.address,
          city: this.city,
          zipCode: this.zipCode,
          country: this.country,
          planId: this.planId,
          trialMonth: this.trialMonth,
        });
        this.$emit("clientSaved", response.data);
      } catch (err) {
        this.$emit("failedCreatingClient", err);
      }
    },
  },
};
</script>
